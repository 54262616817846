import React from 'react'
import Img from 'gatsby-image'

import { EvaluateCard, Title } from './card.atom'

class UserEvaluateCard extends React.Component {
  render() {
    const { card } = this.props
    return (
      <EvaluateCard>
        <Img fluid={card.avatar.fluid} alt={card.avatar.title} className='img-box' />
        <p>{card.description}</p>
        <p>
          <span>{card.author}</span>
          <br />
          <Title>{card.production}</Title>
        </p>
      </EvaluateCard>
    )
  }
}

export default UserEvaluateCard
