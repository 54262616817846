import React from 'react'
import { graphql } from 'gatsby'
import { Billboard, SEO, H1 } from '@renderbus/common/components'

import Layout from '../molecules/layout'
import RegisterBanner from '../molecules/register-banner'
import UserEvaluateCard from '../molecules/user-evaluation'

import {
  EvaluationTitle,
  DescribeBox,
  BannerContainer,
  Emphasis,
  CircleEmphasis,
  Circle,
  CircleBox,
  BannerBox,
  Content,
  Title,
  HypoEmphasis,
  CardBox,
} from './user-evaluation.atom'

class UserEvaluation extends React.PureComponent {
  render() {
    const {
      location,
      data,
      pageContext: { topThreeShare },
    } = this.props
    const userEvaluationList = data.userEvaluations.nodes
    return (
      <Layout location={location} topThreeShare={topThreeShare}>
        <SEO
          title='瑞云渲染农场体验 - 瑞云客户评价 - Renderbus瑞云渲染'
          description='渲染农场如何选择？哪个渲染农场好？Renderbus瑞云渲染被誉为中国云渲染的先行者，提供7*24小时在线技术支持，专属TD为20万+的渲染用户保驾护航。那瑞云渲染农场怎么样?一起来看用户对Renderbus瑞云渲染的真诚评价吧。'
          keywords='瑞云渲染怎么样，瑞云渲染评价，渲染平台哪家好'
          sharePostSlug='user-evaluation.html'
        />
        <BannerContainer>
          <BannerBox>
            <H1>
              <Emphasis>瑞云渲染</Emphasis>——致力提供高品质的<Emphasis>云渲染服务</Emphasis>
            </H1>
            <CircleBox>
              <Circle>
                <Emphasis>50+</Emphasis>
                <p>用户覆盖国家/地区</p>
              </Circle>
              <Circle>
                <Emphasis className='circle-emphasis'>20+</Emphasis>
                <HypoEmphasis>万</HypoEmphasis>
                <p>渲染用户</p>
              </Circle>
              <Circle>
                <CircleEmphasis>4000+</CircleEmphasis>
                <HypoEmphasis>万</HypoEmphasis>
                <p>每月渲染帧</p>
              </Circle>
            </CircleBox>
          </BannerBox>
          <Billboard
            className='evaluation-background'
            fluid={data.banner.childImageSharp.fluid}
            alt='瑞云渲染农场体验 - 瑞云客户评价 - Renderbus瑞云渲染'
          />
          <DescribeBox>
            <Title>瑞云渲染农场怎么样？</Title>
            <Content>
              Renderbus瑞云渲染农场被誉为中国云渲染的先行者，我们拥有海量的渲染节点，具备7*24小时在线技术支持，并且兼容主流CG软件。瑞云渲染农场获得TPN安全认证，保证用户数据高度安全；利用瑞云自研镭速传输技术，为用户提供大文件高速上传/下载的便利。{' '}
            </Content>
            <Content>
              瑞云渲染农场作为亚洲前沿的云渲染平台，致力于提供专业可靠、安全稳定、可持续创新的云渲染解决方案。用户包括奥斯卡金像奖得主、知名的影视动画、视效、建筑可视化、游戏工作室，拥有包括电影《长津湖》、《战狼2》、《哪吒之魔童降世》、《流浪地球》等一大批优秀案例。想知道瑞云渲染农场怎么样？一起来来看渲染用户对我们的真实评价。{' '}
            </Content>
          </DescribeBox>
        </BannerContainer>
        <EvaluationTitle>瑞云渲染农场用户有话说</EvaluationTitle>
        <CardBox>
          {userEvaluationList.map((c) => (
            <UserEvaluateCard card={c} key={c.author} />
          ))}
        </CardBox>
        <RegisterBanner isNoLimit />
      </Layout>
    )
  }
}

export default UserEvaluation

export const query = graphql`
  query {
    banner: file(relativePath: { regex: "/user-evaluation/bg.png/" }) {
      ...fluidImage
    }
    userEvaluations: allContentfulUserEvaluation(sort: { fields: rank, order: DESC }) {
      nodes {
        author
        description
        production
        avatar {
          title
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`
