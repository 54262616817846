import styled from 'styled-components'
import { Media, typography, color, shadows } from '@renderbus/common/theme'

export const EvaluateCard = styled.div`
  width: 450px;
  position: relative;
  height: 320px;
  margin: 75px 12px 40px 12px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  align-items: center;
  padding: 0 2.5em;
  box-shadow: ${shadows[3]};
  padding-top: 60px;
  background-color: rgba(51, 51, 51, 1);
  .img-box {
    width: 140px;
    height: 140px;
    top: -75px;
    position: absolute !important;
  }
  ${Media.lessThan(Media.small)} {
    height: 240px;
    max-width: 337px;
    width: 100%;
    font-size: ${typography.textSmall};
    .img-box {
      transform: scale(0.8);
    }
  }
`
export const Title = styled.span`
  line-height: 2;
  color: ${color.prompt};
`
