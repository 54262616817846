import styled from 'styled-components'
import { H1 } from '@renderbus/common/components'
import { color, Media, typography } from '@renderbus/common/theme'

export const BannerContainer = styled.div`
  position: relative;
  height: 680px;
  z-index: 0;
  text-align: center;
  ${H1} {
    margin-top: 240px;
  }
  ${Media.lessThan(Media.small)} {
    height: auto;
    ${H1} {
      font-size: 30px;
      width: 300px;
      margin: 136px auto 0;
    }
    .evaluation-background {
      height: 370px;
    }
  }
`
export const Emphasis = styled.em`
  color: ${color.primary};
  font-style: normal;
  font-size: 48px;
  font-weight: bold;
  ${Media.lessThan(Media.small)} {
    font-size: 30px;
    line-height: 13px;
  }
`
export const BannerBox = styled.div`
  display: flex;
  flex-flow: column;
`
export const CircleBox = styled.div`
  display: flex;
  width: 788px;
  margin: 113px auto 20px auto;
  justify-content: space-between;
  ${Media.lessThan(Media.small)} {
    margin: 40px auto 0 auto;
    width: auto;
    min-width: 333px;
  }
`
export const Circle = styled.div`
  width: 186px;
  height: 172px;
  border: 4px solid ${color.primary};
  border-radius: 50%;
  box-sizing: border-box;
  border-bottom-color: transparent;
  font-size: 14px;
  font-weight: 400;
  color: ${color.primary};
  line-height: 24px;
  padding-top: 56px;
  :last-child {
    padding-top: 63px;
  }
  p {
    font-weight: bold;
    font-weight: 400;
  }
  ${Media.lessThan(Media.small)} {
    max-width: 107px;
    font-size: 10px;
    line-height: 13px;
    padding-top: 35px;
    height: 107px;
    border: 2px solid ${color.primary};
    border-bottom-color: transparent;
    .circle-emphasis {
      font-size: 24px;
      line-height: 13px;
    }
    :first-child {
      padding-top: 32px;
    }
    :last-child {
      padding-top: 35px;
    }
    p {
      font-size: 10px;
      line-height: 17px;
      margin: 2px 0;
    }
  }
`
export const CircleEmphasis = styled.em`
  color: ${color.primary};
  font-size: 40px;
  font-style: normal;
  font-weight: bold;
  line-height: 18px;
  ${Media.lessThan(Media.small)} {
    font-size: 24px;
    line-height: 13px;
  }
`
export const HypoEmphasis = styled.div`
  font-size: 18px;
  color: ${color.primary};
  line-height: 24px;
  display: inline-block;
  font-weight: bold;
  ${Media.lessThan(Media.small)} {
    font-size: 12px;
    line-height: 13px;
  }
`
export const DescribeBox = styled.div`
  max-width: 1282px;
  margin: auto;
  background: ${color.panel};
  border: 2px solid ${color.primary};
  padding: 51px 40px 16px 40px;
  text-align: start;
  ${Media.lessThan(Media.small)} {
    width: calc(100% - 33px);
    padding: 40px 21px 5px 40px;
    margin-top: 14px;
  }
`
export const Title = styled.div`
  font-weight: bold;
  color: #ffffff;
  line-height: 27px;
  font-size: ${typography.title};
  margin-bottom: 28px;
  ${Media.lessThan(Media.small)} {
    font-size: 18px;
    line-height: 13px;
  }
`

export const Content = styled.div`
  color: #ffffff;
  font-size: ${typography.textSmall};
  margin-bottom: 35px;
`

export const CardBox = styled.div`
  display: flex;
  max-width: 1440px;
  flex-flow: wrap;
  margin: 0 auto 40px auto;
  ${Media.lessThan(Media.small)} {
    margin: 0 auto;
    justify-content: center;
  }
`
export const EvaluationTitle = styled.div`
  margin: 295px auto 95px auto;
  font-size: 40px;
  font-weight: bold;
  color: #ffffff;
  line-height: 82px;
  text-align: center;
  ${Media.lessThan(Media.small)} {
    margin: 43px auto 41px auto;
    font-size: 18px;
    line-height: 41px;
  }
`
